export enum Service {
	Integration = "Integration",
	Consultation = "Consultation",
	Service = "Service",
	Financing = "Financing",
}

export type ServiceWithPage = Extract<Service, Service.Integration | Service.Service>;

export const ServiceWithPage = {
	[Service.Service]: Service.Service,
	[Service.Integration]: Service.Integration,
} satisfies Record<ServiceWithPage, ServiceWithPage>;
