import { Locale, type LocalizedString } from "../Locale.js";
import { Service, type ServiceWithPage } from "../Service.js";

export const servicesCategoriesPathMap: Record<ServiceWithPage, LocalizedString> = {
	[Service.Integration]: {
		[Locale.cs]: "integrace",
		[Locale.en]: "integration",
	},
	[Service.Service]: {
		[Locale.cs]: "podpora",
		[Locale.en]: "support",
	},
};

export const servicesPathMap = {
	[Locale.cs]: "sluzby",
	[Locale.en]: "services",
} satisfies LocalizedString;
